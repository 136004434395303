import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));

function MainApp() {
  window.location.href = 'https://lobobot.com/aramrecap';
  return (
    <div>Redirecting to <a href="https://lobobot.com/aramrecap">https://lobobot.com/aramrecap</a>...</div>
  );
}

root.render(<MainApp />);